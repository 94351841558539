import { atomWithStorage } from "jotai/utils";

const searchProvidersKey = "navirec-map-address-search-backends-v2";
export const addressSearchBackendsAtoms = atomWithStorage<{ [key: string]: { [key: string]: any } } | undefined>(
  searchProvidersKey,
  undefined,
  undefined,
  {
    getOnInit: true,
  }
);
addressSearchBackendsAtoms.debugLabel = "searchProviders";
