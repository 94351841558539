import { CheckOutlined } from "@ant-design/icons";
import { t } from "@lingui/macro";
import { Button, Menu, Popover, Row, Space } from "antd";
import styled from "styled-components";

import CenterIcon from "components/center-icon";

import { useApplicationContext } from "contexts/app";

export const availableLanguageNames: { [key: string]: string } = {
  en: `English`,
  // se: `Swedish`,
  et: `Eesti`,
  de: `Deutsch`,
  es: `Español`,
  ru: `Русский`,
  lv: `Latviešu`,
  lt: `Lietuvių`,
  pl: `Polski`,
  fi: `Suomi`,
  fr: `Français`,
  nl: `Nederlands`,
};

const LanguageMenu = styled(Menu)`
  width: 200px;
  border: none !important;

  .ant-menu-item {
    padding-inline: unset;
    padding: 0 16px;
  }

  margin: -8px;
`;

interface SwitchLanguage {
  reloadApp?: boolean;
}
const SwitchLanguage = ({ reloadApp = false }: SwitchLanguage) => {
  const { language, setLanguage, onMutateConfiguration } = useApplicationContext();

  const languages = Object.keys(availableLanguageNames);

  return (
    <Popover
      placement={"topLeft"}
      trigger={"click"}
      content={
        <LanguageMenu
          onClick={(v) => {
            setLanguage(v.key);
            if (onMutateConfiguration && reloadApp) {
              onMutateConfiguration();
            }
          }}
        >
          {languages.map((key) => (
            <Menu.Item key={key} title={""}>
              <Row justify={"space-between"}>
                <Space align={"center"}>
                  <CenterIcon icon={<img src={`/flags/${key}.svg`} alt={t`country-code`} width="24" height="17" />} />
                  {availableLanguageNames[key]}
                </Space>
                {language === key && <CheckOutlined style={{ color: "green" }} />}
              </Row>
            </Menu.Item>
          ))}
        </LanguageMenu>
      }
    >
      <Button
        type={"link"}
        icon={<img src={`/flags/${language}.svg`} alt={t`country-flag`} width="22" height="16" />}
        data-testid="switch-language-menu"
      />
    </Popover>
  );
};

export default SwitchLanguage;
