import { datadogLogs } from "@datadog/browser-logs";
import { getDefaultStore } from "jotai";
import { atomEffect } from "jotai-effect";
import get from "lodash/get";

import { appConfigAtom } from "atoms/configurations";
import { addressSearchBackendsAtoms } from "components/map/atoms/address-search";
import { mapLayerProviderSelector, mapSettingsAtom } from "components/map/atoms/settings";

const logMapViewSettingAtomEffects = atomEffect((get) => {
  if (get(appConfigAtom)?.loading === false) {
    const accountId = get(appConfigAtom)?.account?.id as string;
    const mapSettings = get.peek(mapSettingsAtom);
    const mapLayerProvider = get.peek(mapLayerProviderSelector);
    const addressSearchBackendProviders = get.peek(addressSearchBackendsAtoms)?.[accountId] || [];

    datadogLogs.logger.info("Map view initialized", {
      "logger.method_name": "logMapViewSettingAtomEffects",
      "logger.name": "navirec_next.components.map.atoms.logs",
      "map_view.settings": { ...mapSettings, mapLayerProvider, addressSearchBackendProviders },
    });
  }
});

const ignoreLogsKeys = ["activeRightSidePanel"];
const store = getDefaultStore();

const logChangedMapViewSettings = (key: string, value: any) => {
  if (ignoreLogsKeys.includes(key)) {
    return;
  }

  const mapSettings = store.get(mapSettingsAtom);

  datadogLogs.logger.info("Map view settings changed", {
    "logger.method_name": "logChangedMapViewSettings",
    "logger.name": "navirec_next.components.map.atoms.logs",
    "map_view.settings_changed.field_name": key,
    "map_view.settings_changed.old_value": get(mapSettings, key),
    "map_view.settings_changed.new_value": value,
  });
};

export { logMapViewSettingAtomEffects, logChangedMapViewSettings };
