import dayjs, { Dayjs } from "dayjs";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { atomWithStorage, selectAtom } from "jotai/utils";
import jwtDecode from "jwt-decode";
import { delay, isEmpty } from "lodash";

import { appConfigAtom } from "atoms/configurations";
import { httpClientAtom } from "atoms/httpclient";
import { userSessionAtom } from "atoms/session";
import { sessionStorage } from "atoms/utils";

import { Configuration } from "types";

export const REFRESH_TOKEN_BEFORE = 1 * 60; // refresh token before expiry time

const retryAuthorizeTokenAfterAtom = atom<Dayjs | undefined>(undefined);
retryAuthorizeTokenAfterAtom.debugLabel = "retryAuthorizeTokenAfter";

const loadingAuthorizeTokenAtom = atom<boolean>(false);
loadingAuthorizeTokenAtom.debugLabel = "loadingAuthorizeToken";

const userAuthorizeTokenAtom = atomWithStorage<string>("navirec-settings-authorize-token", "", undefined, {
  getOnInit: true,
});
userAuthorizeTokenAtom.debugLabel = "authorizeToken";

const hijackAuthorizeTokenAtom = atomWithStorage<string>("navirec-settings-authorize-token", "", sessionStorage);
hijackAuthorizeTokenAtom.debugLabel = "hijackAuthorizeToken";

const authorizeTokenAtom = atom((get) => {
  const isHijacked = get(userSessionAtom)?.isHijacked;

  if (isHijacked) {
    return get(hijackAuthorizeTokenAtom);
  }

  return get(userAuthorizeTokenAtom);
});

const refreshAuthorizeTokenAtom = atom<{ onRefreshToken: (cb?: () => void) => void }>({ onRefreshToken: () => {} });
refreshAuthorizeTokenAtom.debugPrivate = true;

export const accountUrlSelector = (config?: Configuration) => config?.account?.url;
const initializeAuthorizeTokenAtomEffect = atomEffect((get, set) => {
  const accountURL = get(selectAtom(appConfigAtom, accountUrlSelector)) || "";
  const httpClient = get(httpClientAtom);
  const loading = get(loadingAuthorizeTokenAtom);
  const isHijacked = get(userSessionAtom)?.isHijacked;

  if ((typeof window !== "undefined" && window.Cypress) || isEmpty(accountURL) || loading) {
    return;
  }

  const validateToken = (token: string) => {
    try {
      const decodedToken = jwtDecode(token) as { exp: number };
      const expireAt = dayjs(decodedToken.exp * 1000);
      const refreshAt = expireAt.diff(dayjs().add(REFRESH_TOKEN_BEFORE, "seconds"), "milliseconds");
      const isExpired = expireAt.isBefore(dayjs());
      const timeout = isExpired ? 0 : refreshAt;

      if (isExpired) {
        refreshToken(accountURL);
      } else {
        delay(() => {
          refreshToken(accountURL);
        }, timeout);
      }
    } catch (e) {
      const err = e as any;
      const errorName = err?.name;
      // If token is invalid or corrupted try and request a new token
      if (errorName === "InvalidTokenError") {
        refreshToken(accountURL);
      }
      console.error("An error occurred while trying to decode token:", err);
    }
  };

  const refreshToken = async (accountURL: string, successCallback?: () => void) => {
    const retryAuthorizeTokenAfter = get(retryAuthorizeTokenAfterAtom);

    if (get(loadingAuthorizeTokenAtom)) {
      return;
    }

    if (!isEmpty(retryAuthorizeTokenAfter) && dayjs()?.isBefore(retryAuthorizeTokenAfter)) {
      return;
    }

    set(loadingAuthorizeTokenAtom, true);
    await httpClient.post("authorize/", {
      data: {
        account: accountURL,
      },
      successHandler: (res: { token: string }) => {
        if (isHijacked) {
          set(hijackAuthorizeTokenAtom, res?.token);
        } else {
          set(userAuthorizeTokenAtom, res?.token);
        }
        set(loadingAuthorizeTokenAtom, false);
        successCallback && successCallback();

        const { exp }: any = jwtDecode(res.token);
        // refresh token 10 seconds before expiration time.
        const refreshAt = dayjs(exp * 1000).diff(dayjs().add(REFRESH_TOKEN_BEFORE, "seconds"), "milliseconds");

        delay(() => {
          refreshToken(accountURL);
        }, refreshAt);
        set(retryAuthorizeTokenAfterAtom, dayjs().add(refreshAt, "milliseconds"));
      },
      errorHandler: (err) => {
        set(loadingAuthorizeTokenAtom, false);
      },
    });
  };

  const initToken = () => {
    const authorizeToken = get(authorizeTokenAtom) as string;

    if (authorizeToken) {
      validateToken(authorizeToken);
    } else {
      refreshToken(accountURL);
    }
  };

  initToken();

  set(refreshAuthorizeTokenAtom, {
    onRefreshToken: (callback?: () => void) => refreshToken(accountURL, callback),
  });
});

export { initializeAuthorizeTokenAtomEffect, authorizeTokenAtom, refreshAuthorizeTokenAtom, loadingAuthorizeTokenAtom };
