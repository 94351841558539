import React, { useState } from "react";

import { t } from "@lingui/macro";
import { Alert, Button, Col, Typography } from "antd";
import { setCookie } from "cookies-next";
import { useAtomValue } from "jotai/index";
import { last } from "lodash";
import styled from "styled-components";

import { listAndMapViewWidthSelector } from "components/map/atoms/settings";

import { mapviewVersionKey, showFallbackBanner } from "components/map/config";

const StyledAlert = styled(Alert)<{ width: number }>`
  z-index: 9999;
  width: ${(props) => props.width}px;
`;
export const oneWeekMaxAge = 168 * 60 * 60; // 1 week in seconds

const UseOldVersionBanner = () => {
  const listAndMapViewWidth = useAtomValue(listAndMapViewWidthSelector);

  const [closedBanner, setClosedBanner] = useState(false);

  const useOldVersion = () => {
    setCookie(mapviewVersionKey, "old", { maxAge: oneWeekMaxAge });
    window.location.href = "/map";
  };

  if (!showFallbackBanner || closedBanner) {
    return null;
  }

  const width = Number(last(listAndMapViewWidth)) - 70;

  return (
    <Col span={24}>
      <StyledAlert
        width={width}
        message={
          <Typography.Text ellipsis>
            {t`If you're having trouble using this version you can leave us feedback using the live chat button at bottom left of this page and switch to old version.`}
          </Typography.Text>
        }
        type="info"
        showIcon
        action={
          <Button size="small" type="text" onClick={useOldVersion}>
            {t`Use old version`}
          </Button>
        }
        closable
        onClose={() => {
          setClosedBanner(true);
        }}
      />
    </Col>
  );
};

export default UseOldVersionBanner;
