import { useState } from "react";

import { Select } from "antd";
import { debounce } from "lodash";
import styled from "styled-components";
import useSWR from "swr";

import { useApplicationContext } from "contexts/app";

import { Account } from "types";

import { onChangeAccount } from "utils/authentication-utils";
import { useSetAtom } from "jotai";
import { previousAccountIdAtom } from "atoms/session";

const ACCOUNTS_FETCH_LIMIT = 20;
const DELAY = 500;

const useFetchAccount = (pageSize: number) => {
  const url = `accounts/?ordering=name&page_size=${pageSize}`;
  const [search, setSearch] = useState("");

  const accountsURL = `${url}${search ? `&search=${encodeURIComponent(search)}` : `&explicit_permissions=can_access`}`;

  const { data: accounts, error } = useSWR<Account[]>(accountsURL, {
    revalidateIfStale: false,
  });

  const onSearch = debounce((value) => setSearch(value), DELAY);
  const loading = accounts === undefined && error === undefined;

  return { accounts, onSearch, loading };
};

const Container = styled(Select)`
  width: 100%;
`;

const ChangeAccount = () => {
  const { configuration } = useApplicationContext();
  const setPreviousAccountId = useSetAtom(previousAccountIdAtom);

  const accountName = configuration?.account?.name;
  const currentAccountId = configuration?.account?.id;

  const { accounts, onSearch, loading } = useFetchAccount(ACCOUNTS_FETCH_LIMIT);

  return (
    <Container
      value={accountName}
      onChange={async (value) => {
        if (typeof value === "string") {
          await setPreviousAccountId(currentAccountId);
          await onChangeAccount(value);
        }
      }}
      showSearch
      onSearch={onSearch}
      loading={loading}
      filterOption={false}
    >
      {accounts?.map((account: Account) => {
        return (
          <Select.Option value={account.id} key={account.url}>
            {account.name}
          </Select.Option>
        );
      })}
    </Container>
  );
};

export default ChangeAccount;
