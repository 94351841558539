import React from "react";

import Head from "next/head";
import { useRouter } from "next/router";

import AntdConfiguration from "components/antd/config";

import designTokens from "design/tokens.json";
import { DevTools } from "jotai-devtools";
import "jotai-devtools/styles.css";
import Layout, { AppProps } from "layout";
import { NextPage } from "next";
import "nprogress/nprogress.css";
import "overlayscrollbars/overlayscrollbars.css";
import "styles/override-antd.css";
import "styles/override-progress.css";

import HydrateAtoms from "atoms/hydrate";
import { nextRouterAtom } from "atoms/router";
import { ironSessionAtom } from "atoms/session";

import DeploymentManager from "components/deployment-manager";
import LanguageWrapper from "components/language-wrapper";

import { ApplicationProvider } from "contexts/app";

const Navirec: NextPage<AppProps> = ({ Component, pageProps }) => {
  const router = useRouter();

  return (
    <LanguageWrapper session={pageProps?.session}>
      <Head>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
      </Head>
      <DevTools isInitialOpen={false} position={"bottom-right"} />
      <HydrateAtoms
        initialValues={[
          [ironSessionAtom, pageProps?.session],
          [nextRouterAtom, router],
        ]}
      >
        <AntdConfiguration designTokens={designTokens}>
          <ApplicationProvider>
            <Layout layout={Component.Layout} privateLayoutProps={Component.PrivateLayoutProps}>
              <DeploymentManager />
              <Component {...pageProps} />
            </Layout>
          </ApplicationProvider>
        </AntdConfiguration>
      </HydrateAtoms>
    </LanguageWrapper>
  );
};

export default Navirec;
