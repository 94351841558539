import { atomWithStorage, createJSONStorage } from "jotai/utils";

import { SyncStorage } from "jotai/vanilla/utils/atomWithStorage";

/*This utility function is to disable stringify for strings value when using atomWithStorage.*/
const stringStorage: SyncStorage<any> = createJSONStorage();
stringStorage.setItem = (key: string, newValue) =>
  typeof localStorage !== "undefined" && localStorage.setItem(key, newValue as string);
stringStorage.getItem = (key: string) => (typeof localStorage !== "undefined" ? localStorage.getItem(key) : "");

const sessionStorage_ = createJSONStorage(() => sessionStorage) as any;

type Migration<T> = {
  key: string;
  fieldMappings: { [key: string]: any };
  transform?: (data: T) => T;
};

/**
 * Create a versioned atom with storage, handling migrations and cleaning up old storage keys.
 *
 * @template T - The type of the data stored in the atom.
 * @param storageKey - The base key for localStorage.
 * @param defaultValue - The default value for the atom.
 * @param migrations - List of migrations for previous versions.
 * @returns A Jotai atom with storage that handles migrations.
 */
const versionedAtomWithStorage = <T>(storageKey: string, defaultValue: T, migrations: Migration<T>[] = []) => {
  if (typeof localStorage === "undefined") {
    return atomWithStorage<T>(storageKey, defaultValue);
  }

  // Helper to migrate data from older versions
  const migrateData = (): T => {
    for (const { key, fieldMappings, transform } of migrations) {
      const oldDataJson = localStorage.getItem(key);
      if (oldDataJson) {
        const oldData = JSON.parse(oldDataJson) as Partial<T>;

        // Perform field renaming
        let migratedData = Object.entries(oldData).reduce((acc: Record<any, any>, [oldKey, value]) => {
          const newKey = fieldMappings[oldKey] || oldKey; // Map old key to new key if specified
          acc[newKey as keyof T] = value;
          return acc;
        }, {} as T);

        // Apply custom transformation if provided
        if (transform) {
          migratedData = transform(migratedData);
        }

        // Save the migrated data to the new storage key
        localStorage.setItem(storageKey, JSON.stringify(migratedData));

        // Clean up old storage key
        localStorage.removeItem(key);

        return migratedData;
      }
    }
    return defaultValue; // Use default value if no data is found
  };

  // Check if the current version's data exists, otherwise migrate
  const initialData = JSON.parse(localStorage.getItem(storageKey) || "null") ?? migrateData();

  return atomWithStorage<T>(storageKey, initialData);
};

export { stringStorage, sessionStorage_ as sessionStorage, versionedAtomWithStorage };
