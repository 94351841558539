const isURLString = (url?: string) => {
  if (!url) {
    return false;
  }

  return url.startsWith("http://") || url.startsWith("https://");
};

const shortenString = (value: string | undefined, limit: number = 7): string => {
  if (!value) return "";
  return value.length > limit ? value.slice(0, limit) : value;
};

export { isURLString, shortenString };
