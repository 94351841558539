import { isMobile } from "react-device-detect";

import { getCookie, setCookie } from "cookies-next";
import { atom } from "jotai";
import { atomEffect } from "jotai-effect";
import { isEmpty } from "lodash";
import lodashGet from "lodash/get";

import { httpClientAtom, initializeHttpClientEffectAtom } from "atoms/httpclient";
import { languageAtom } from "atoms/language";
import { nextRouterAtom } from "atoms/router";
import { loadingSessionAtom, previousAccountIdAtom, userSessionAtom } from "atoms/session";

import { oneWeekMaxAge } from "components/map/use-old-version-banner";

import { Configuration } from "types";

import createHTTPClient from "utils/http-client-utils";
import { shortenString } from "utils/url-utils";

const excludeLoadingConfigRoutes = ["login", "passwordforgot", "passwordreset", "design-system"];
const RELEASE_TRACK_COOKIE = "navirec-release-track" as any;
const VERCEL_GIT_COMMIT_SHA = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA || "development";

export const toggleLeftSideBarAtom = atom<boolean>(isMobile);
toggleLeftSideBarAtom.debugLabel = "toggleLeftSideBar";

interface AppConfig extends Configuration {
  loading?: boolean;
  error?: any;
  onRefresh?: () => void;
}

const appConfigAtom = atom<AppConfig | undefined>({ loading: true, error: undefined, onRefresh: () => {} });
appConfigAtom.debugLabel = "appConfigAtom";

const countUnReadMessagesAtom = atom<number>(0);
countUnReadMessagesAtom.debugLabel = "countUnReadMessages";

const appConfigEffectAtom = atomEffect((get, set) => {
  get(initializeHttpClientEffectAtom);

  const isLoadingSession = get(loadingSessionAtom);
  const httpClient = get(httpClientAtom);
  const session = get(userSessionAtom);
  const router = get(nextRouterAtom);

  const pathname = window.location.pathname || "";
  const cleanPathname = pathname?.replaceAll("/", "");
  const shouldNotLoadConfig = excludeLoadingConfigRoutes.includes(cleanPathname);

  if (shouldNotLoadConfig || isLoadingSession || isEmpty(httpClient.basePath)) {
    set(appConfigAtom, (res) => ({ ...res, loading: false, error: undefined }));
    return;
  }

  const configURL = session
    ? `/configuration/?app=web&account=${session?.accountId}&user=${session?.userId}&version=${shortenString(
        VERCEL_GIT_COMMIT_SHA
      )}`
    : undefined;

  if (configURL) {
    const loadConfig = () => {
      const lang = get.peek(languageAtom);
      set(appConfigAtom, (res) => ({ ...res, loading: true, error: undefined }));

      return httpClient.get(configURL, {
        config: { headers: { "Accept-Language": lang } },
        successHandler: (data) => {
          // release track management.
          const releaseTrack = lodashGet(data, "environment.web_app_release_track", "live");
          const releaseTrackCookie = getCookie(RELEASE_TRACK_COOKIE) || "live";

          if (releaseTrack !== releaseTrackCookie) {
            setCookie(RELEASE_TRACK_COOKIE, releaseTrack, { maxAge: oneWeekMaxAge });
            router?.reload();
            return;
          }

          // re-initialize http client with account timezone
          const timezone = lodashGet(data, "account.timezone");
          set(httpClientAtom, createHTTPClient(httpClient.basePath as string, session?.token, timezone, lang));

          set(appConfigAtom, (res) => {
            return { ...res, ...data, loading: false, error: undefined, onRefresh: loadConfig };
          });
          set(previousAccountIdAtom, "");
        },
        errorHandler: (err) => {
          set(appConfigAtom, (res) => {
            return { ...res, loading: false, error: err };
          });
        },
      });
    };

    loadConfig();
  } else {
    set(appConfigAtom, (res) => ({ ...res, loading: false, error: undefined }));
  }
});
appConfigEffectAtom.debugLabel = "appConfigEffect";
appConfigEffectAtom.debugPrivate = true;

export { appConfigEffectAtom, appConfigAtom, countUnReadMessagesAtom };
