import { t } from "@lingui/macro";
import { FormInstance, message, Modal } from "antd";
import { isArray, isEmpty, isPlainObject, isString } from "lodash";
import keys from "lodash/keys";

import { httpStatusCodes } from "utils/status-codes-utils";

const ignoreErrors = ["Request aborted"];

const flattenObj = (obj: any, nkey = "", res: any = {}) => {
  for (let key in obj) {
    let keys = nkey ? nkey + "_" + key : key;

    switch (true) {
      case isArray(obj[key]):
        res[keys] = obj[key].toString();
        break;
      case typeof obj[key] == "object":
        flattenObj(obj[key], keys, res);
        break;
      default:
        res[keys] = obj[key];
    }
  }

  return res;
};

const getErrorTitle = (errorCode: number | undefined = undefined) => {
  let errorTitle = t`Something went wrong`;

  if (errorCode === 400) {
    errorTitle = t`Validation error`;
  }

  return errorTitle;
};

const parseErrorResponse = (error: any, form: any, alias: any = undefined, errorCode?: number) => {
  const nonFieldKeys: { [key: string]: any } = {};

  keys(error)?.forEach((key: string | number) => {
    const aliasKey = alias ? alias[key] || key : key;
    const fieldKey = aliasKey || key;

    // check that form instance exist and check error field is existing in form instance
    if (form && keys(form.getFieldsValue()).includes(aliasKey)) {
      switch (true) {
        // check and handle error if type is an object and not an array
        case isPlainObject(error[key]):
          const errors = error[key];

          // iterate over errors and map to field.
          Object.keys(errors).map((errFieldKey: string) => {
            form.setFields([{ name: [fieldKey, errFieldKey], errors: errors[errFieldKey] }]);
          });
          break;
        case isString(error[key]):
        case isArray(error[key]):
          form.setFields([{ name: fieldKey, errors: error[key] }]);
          break;
        default:
          console.error("Error unhandled error type", typeof error[key]);
      }
    } else {
      // if keys is non-existent in form field show error using modal
      nonFieldKeys[key] = error[key];
    }
  });

  if (!isEmpty(nonFieldKeys)) {
    const msg = flattenObj(nonFieldKeys);

    const errMsg = (
      <div>
        {Object.keys(msg).map((msgKey, i) => {
          return (
            <div key={i}>
              {msgKey.replaceAll("_", " ")}: {msg[msgKey]}
            </div>
          );
        })}
      </div>
    );

    Modal.error({
      title: getErrorTitle(errorCode),
      content: <>{errMsg}</>,
      okText: t`Dismiss`,
    });
  }

  return;
};

const showError = (content: any) => {
  message.open({ type: "error", content, key: "http-client-error" });
};

const onHandleError = (err: any, form?: FormInstance | null, alias: any = undefined) => {
  // ignore 429 errors because it is already handled by throttleRequest interceptor.
  if (ignoreErrors.includes(err) || err?.status === httpStatusCodes.TOO_MANY_REQUESTS) {
    // ignore some errors eg request aborted etc.
    return;
  }

  const error = err?.data;
  const errorCode = err?.status;

  if (error?.non_field_errors) {
    return showError(error?.non_field_errors?.[0]);
  }

  if (errorCode === httpStatusCodes.UNAUTHORIZED) {
    if (location.pathname !== "/login/") {
      return (window.location.href = "/login");
    }
    return;
  }

  switch (true) {
    case !isEmpty(error?.detail):
      return showError(error?.detail);
    case typeof error === "object":
      return parseErrorResponse(error, form, alias, errorCode);
    case typeof error === "string":
      return showError(error);
    case typeof err === "string":
      showError(err);
  }
};

const onClearFieldsError = (form: FormInstance) => {
  const fields = form.getFieldsError();
  const errorFields = fields.filter((row) => !isEmpty(row.errors));

  errorFields.forEach((field) => {
    form.setFields([{ name: field.name, errors: [] }]);
  });
};

/**
 * Formats nested error objects into a readable string
 * @param errors - Array of error objects with field-level validation messages
 * @param ignoreKeys - Ignore error keys
 * @returns Formatted error string with field names and their messages
 */
const formatErrorMessage = (errors?: Array<Record<string, string[]>>, ignoreKeys?: boolean): string => {
  if (!errors || isEmpty(errors)) {
    return "";
  }

  return errors
    ?.map((errorObj) => {
      // Get all field names and their error messages from the object
      return Object?.entries(errorObj)
        ?.map(([field, messages]) => {
          // Capitalize the field name and join multiple messages with semicolons
          const fieldName = field.charAt(0).toUpperCase() + field.slice(1);
          if (ignoreKeys) {
            return messages.join("; ");
          }
          return `${fieldName}: ${messages.join("; ")}`;
        })
        .join("\n");
    })
    ?.join("\n");
};

export { onHandleError, onClearFieldsError, formatErrorMessage };
