import { useCallback, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { t } from "@lingui/macro";
import { message } from "antd";
import axios from "axios";
import { isEmpty } from "lodash";
import get from "lodash/get";

import Countdown from "components/countdown";

import { httpStatusCodes } from "utils/status-codes-utils";

const BUILD_POLL_INTERVAL = 15; // 15 in minutes;
const appBuildId = process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA;

const useReloadApp = ({ reloadOnNextNavigation }: { reloadOnNextNavigation: boolean }) => {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = async () => {
      if (reloadOnNextNavigation) {
        router.reload();
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [reloadOnNextNavigation]);
};

const useCheckIfNewBuildExist = ({
  setReloadOnNextNavigation,
}: {
  setReloadOnNextNavigation: (v: boolean) => void;
}) => {
  const [stopPolling, setStopPolling] = useState(false);

  const router = useRouter();

  const doAutoReload = useCallback(() => {
    const delay = 60 + Math.random();

    message.open({
      key: "force-reload-app",
      type: "loading",
      content: (
        <Countdown
          deadlineInSeconds={delay}
          message={(time) => {
            if (time) {
              return t`New version available, reloading app in ${Math.round(time)} seconds...`;
            }
          }}
        />
      ),
      duration: delay,
      onClose: () => {
        router.reload();
      },
    });
  }, []);

  useEffect(() => {
    if (isEmpty(appBuildId) || stopPolling) {
      return;
    }

    const interval = setInterval(async () => {
      const response = await axios.get(`/api/check-build/${appBuildId}/`);
      const action = get(response, "data.action");

      if (response.status === httpStatusCodes.OK) {
        if (action === "auto-reload") {
          setStopPolling(true);
          doAutoReload();
        }

        if (action === "reload") {
          setStopPolling(true);
          setReloadOnNextNavigation(true);
        }
      }
    }, BUILD_POLL_INTERVAL * 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [stopPolling, appBuildId]);
};

const DeploymentManager = () => {
  const [reloadOnNextNavigation, setReloadOnNextNavigation] = useState(false);

  useCheckIfNewBuildExist({
    setReloadOnNextNavigation,
  });

  useReloadApp({
    reloadOnNextNavigation,
  });

  return null;
};

export default DeploymentManager;
