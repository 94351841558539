import { CSSProperties, ReactNode } from "react";

import { Trans } from "@lingui/macro";
import dayjs from "dayjs";
import { isEmpty } from "lodash";

import { MapLayers } from "components/deck-gl/instance";

import { smitMapService } from "contexts/app";

export type TileLayer = {
  url: string;
  wms?: boolean;
  mvt?: boolean;
  params?: object;
  extent?: number[];
  minZoom?: number;
  maxZoom?: number;
};

interface Layers {
  [key: string]: {
    name: ReactNode;
    tileLayers: TileLayer[];
    attribution?: ReactNode;
  };
}

const COPYRIGHT_LICENSE_STYLE: CSSProperties = {
  position: "absolute",
  right: 0,
  bottom: 0,
  backgroundColor: "hsla(0,0%,100%,.5)",
  padding: "0 5px",
  font: "12px/20px Helvetica Neue,Arial,Helvetica,sans-serif",
};

const LINK_STYLE: CSSProperties = {
  textDecoration: "none",
  color: "rgba(0,0,0,.75)",
  cursor: "grab",
};

const layerParams = {
  VERSION: "1.1.1",
  FORMAT: "image/png",
  MAXZOOM: 17,
  MINZOOM: 5,
  TILED: true,
  TRANSPARENT: true,
  UPPERCASE: true,
  STYLES: "",
};
const maaametMapExtent: [number, number, number, number] = [
  -30.0, // West (longitude)
  50.0, // South (latitude)
  40.0, // East (longitude)
  75.0, // North (latitude)
];

const estoniaMapExtent: [number, number, number, number] = [21.8324, 57.5093, 28.21, 59.686];

const forceMapBoundsToExtent: { [key: string]: [number, number, number, number] } = {
  "maa-amet-aero": estoniaMapExtent,
  "reach-u": estoniaMapExtent,
  "maa-amet-fields": estoniaMapExtent,
  "maa-amet-kataster": estoniaMapExtent,
  "forest-registry": estoniaMapExtent,
};

const mapLayers = (tracesTrackKey?: string): Layers => {
  return {
    "google-road": {
      name: <Trans>Google Road</Trans>,
      tileLayers: [],
    },
    "google-hybrid": {
      name: <Trans>Google Hybrid</Trans>,
      tileLayers: [],
    },
    "google-satellite": {
      name: <Trans>Google Satellite</Trans>,
      tileLayers: [],
    },
    "reach-u": {
      name: <Trans>Regio Baltic</Trans>,
      tileLayers: [
        {
          url: "//pump.reach-u.com/gsmvalve/{z}/{x}/{y}.png?type=google",
        },
      ],
      attribution: (
        <div style={COPYRIGHT_LICENSE_STYLE}>
          <a style={LINK_STYLE} href="https://kaart.regio.ee" target="blank">
            © Regio {dayjs().year()}
          </a>
        </div>
      ),
    },
    "tracestrack-raster": {
      name: <Trans>Tracestrack (raster)</Trans>,
      tileLayers: [
        {
          url: `https://tile.tracestrack.com/_/{z}/{x}/{y}.png?key=${tracesTrackKey}`,
        },
      ],
      attribution: (
        <div style={COPYRIGHT_LICENSE_STYLE}>
          <a style={LINK_STYLE} href="https://maplibre.org/" target="_blank">
            © MapLibre
          </a>
          {" | "}
          <a style={LINK_STYLE} href="https://www.openmaptiles.org/" target="_blank">
            © OpenMapTiles
          </a>{" "}
          <a style={LINK_STYLE} href="https://www.openstreetmap.org/copyright" target="_blank">
            © OpenStreetMap contributors
          </a>
        </div>
      ),
    },
    "tracestrack-vector": {
      name: <Trans>Tracestrack (vector)</Trans>,
      tileLayers: [
        {
          mvt: true,
          url: `https://tile.tracestrack.com/v/maps/carto/style.json?key=${tracesTrackKey}`,
        },
      ],
      attribution: (
        <div style={COPYRIGHT_LICENSE_STYLE}>
          <a style={LINK_STYLE} href="https://maplibre.org/" target="_blank">
            © MapLibre
          </a>
          {" | "}
          <a style={LINK_STYLE} href="https://www.openmaptiles.org/" target="_blank">
            © OpenMapTiles
          </a>{" "}
          <a style={LINK_STYLE} href="https://www.openstreetmap.org/copyright" target="_blank">
            © OpenStreetMap contributors
          </a>
        </div>
      ),
    },
    "maptiler-streets-v2": {
      name: <Trans>MapTiler</Trans>,
      tileLayers: [
        {
          mvt: true,
          url: "streets-v2",
        },
      ],
      attribution: (
        <div style={COPYRIGHT_LICENSE_STYLE}>
          <a style={LINK_STYLE} href="https://www.maptiler.com" target="_blank">
            © MapTiler
          </a>
          <a style={LINK_STYLE} href="https://www.openstreetmap.org/copyright" target="_blank">
            © OpenStreetMap contributors
          </a>
        </div>
      ),
    },
    "maptiler-openstreetmap": {
      name: <Trans>MapTiler (openstreetmap)</Trans>,
      tileLayers: [
        {
          mvt: true,
          url: "openstreetmap",
        },
      ],
      attribution: (
        <div style={COPYRIGHT_LICENSE_STYLE}>
          <a style={LINK_STYLE} href="https://www.maptiler.com" target="_blank">
            © MapTiler
          </a>
          <a style={LINK_STYLE} href="https://www.openstreetmap.org/copyright" target="_blank">
            © OpenStreetMap contributors
          </a>
        </div>
      ),
    },
    "maa-amet-aero": {
      name: <Trans>Maa-Amet Aerofoto</Trans>,
      tileLayers: [
        {
          url: "https://tiles.maaamet.ee/tm/tms/1.0.0/foto@GMC/{z}/{x}/{-y}.jpeg",
          extent: forceMapBoundsToExtent["maa-amet-aero"],
          minZoom: 6,
          maxZoom: 18,
        },
      ],
    },
    "maa-amet-fields": {
      name: <Trans>Pōllumassiivid</Trans>,
      tileLayers: [
        {
          url: "https://tiles.maaamet.ee/tm/tms/1.0.0/kaart@GMC/{z}/{x}/{-y}.png",
          extent: maaametMapExtent,
          minZoom: 4,
          maxZoom: 18,
        },
        {
          wms: true,
          url: "https://kls.pria.ee/geoserver/pria_avalik/wms",
          params: {
            ...layerParams,
            SRS: "EPSG:3857",
            LAYERS: "pria_massiivid",
          },
          minZoom: 4,
          maxZoom: 18,
        },
      ],
    },
    "maa-amet-kataster": {
      name: <Trans>Katastrikaart</Trans>,
      tileLayers: [
        {
          url: "https://tiles.maaamet.ee/tm/tms/1.0.0/kaart@GMC/{z}/{x}/{-y}.png",
          extent: maaametMapExtent,
          minZoom: 4,
          maxZoom: 18,
        },
        {
          wms: true,
          url: "https://gsavalik.envir.ee/geoserver/kataster/ows",
          params: {
            ...layerParams,
            SRS: "EPSG:3857",
            LAYERS: "ky_kehtiv,ky_kehtiv_tunnusega",
          },
          minZoom: 4,
          maxZoom: 18,
        },
      ],
    },
    "forest-registry": {
      name: <Trans>Forest Registry</Trans>,
      tileLayers: [
        {
          url: "https://tiles.maaamet.ee/tm/tms/1.0.0/kaart@GMC/{z}/{x}/{-y}.png",
          extent: maaametMapExtent,
          minZoom: 4,
          maxZoom: 18,
        },
        {
          wms: true,
          url: "https://gsavalik.envir.ee/geoserver/mr_portaal/ows",
          params: {
            ...layerParams,
            SRS: "EPSG:3857",
            LAYERS: "mr_portaal:eraldis-era,mr_portaal:eraldis-rmk",
          },
          minZoom: 4,
          maxZoom: 18,
        },
      ],
    },
    "open-street-maps": {
      name: <Trans>OpenStreetMap</Trans>,
      tileLayers: [
        {
          url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        },
      ],
      attribution: (
        <div style={COPYRIGHT_LICENSE_STYLE}>
          {"© "}
          <a style={LINK_STYLE} href="http://www.openstreetmap.org/copyright" target="blank">
            OpenStreetMap
          </a>
        </div>
      ),
    },
    "jana-seta": {
      name: <Trans>Jana Seta</Trans>,
      tileLayers: [
        {
          wms: true,
          url: "https://wms.kartes.lv/NAVIREC/wgs/15/",
          params: {
            ...layerParams,
            SRS: "EPSG:3857",
            version: "1.3.0",
          },
        },
      ],
      attribution: (
        <div style={COPYRIGHT_LICENSE_STYLE}>
          {"© "}
          <a style={LINK_STYLE} href="https://www.kartes.lv/lv/" target="blank">
            kartes
          </a>
        </div>
      ),
    },
    ...(smitMapService && {
      "sim-base": {
        name: <Trans>SiM Alus</Trans>,
        tileLayers: [
          {
            wms: true,
            url: smitMapService,
            params: {
              ...layerParams,
              layers: "sim_aluskaart",
              rakendus: "gsmvalve",
            },
          },
        ],
      },
      "sim-orto": {
        name: <Trans>SiM Ortofoto</Trans>,
        tileLayers: [
          {
            wms: true,
            url: smitMapService,
            params: {
              ...layerParams,
              layers: "sim_ortofoto",
              rakendus: "gsmvalve",
            },
          },
        ],
      },
      "sim-hybrid": {
        name: <Trans>SiM hübriidkaart</Trans>,
        tileLayers: [
          {
            wms: true,
            url: smitMapService,
            params: {
              ...layerParams,
              layers: "sim_hybriidkaart",
              rakendus: "gsmvalve",
            },
          },
        ],
      },
    }),
  };
};

const getMapLayer = (layer: MapLayers, tracesTrackKey?: string) => {
  const data = mapLayers(tracesTrackKey)?.[layer];

  if (isEmpty(data)) return null;

  return data;
};

export { getMapLayer, mapLayers, forceMapBoundsToExtent };
