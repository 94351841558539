import { Trans } from "@lingui/macro";

import { httpStatusCodes } from "utils/status-codes-utils";

const defaultShapeColor = {
  HEXA: "#494F559D",
  RGBA: [73, 79, 85, 102],
};

const defaultAreasHighlightColor = "#FFFF0090";

const defaultTripColor = {
  HEX: "#007df6",
  RGB: [0, 125, 246],
};

const tripTypeAreaOptions = [
  { value: "no_change", label: <Trans>Automatic</Trans> },
  { value: "work_trip", label: <Trans>Work trip</Trans> },
  { value: "private_trip", label: <Trans>Private trip</Trans> },
];

const sseFallbackErrors = [
  httpStatusCodes.NOT_ACCEPTABLE,
  httpStatusCodes.INTERNAL_SERVER_ERROR,
  httpStatusCodes.TEMPORARY_REDIRECT,
  // httpStatusCodes.BAD_GATEWAY,
  // httpStatusCodes.SERVICE_UNAVAILABLE,
  // httpStatusCodes.GATEWAY_TIMEOUT,
  // httpStatusCodes.NOT_IMPLEMENTED,
];

const showFallbackBanner = false;
const showDailyTotalsInLiveView = true;
const showDailyTotalsInTimelineView = false;
const mapviewVersionKey = "navirec-mapview-version-v2";
const dayTotalsReportDayOfWeek = 1; // translates to Monday, Day totals would be calculated for every monday
const maxTimelineDays = 7;
const allowedActivitiesForMaxTimelineDays = ["driving", "parking", "towing"]; // support activities when timeline exceeds `maxTimelineDays`
const pausePlaybackOnChartClick = true;

export {
  defaultTripColor,
  defaultShapeColor,
  tripTypeAreaOptions,
  sseFallbackErrors,
  showFallbackBanner,
  defaultAreasHighlightColor,
  mapviewVersionKey,
  dayTotalsReportDayOfWeek,
  maxTimelineDays,
  allowedActivitiesForMaxTimelineDays,
  showDailyTotalsInLiveView,
  showDailyTotalsInTimelineView,
  pausePlaybackOnChartClick,
};
