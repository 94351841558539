import React from "react";

import { t } from "@lingui/macro";
import { bboxPolygon, booleanPointInPolygon, point } from "@turf/turf";
import dayjs, { Dayjs } from "dayjs";
import { isArray, isEmpty, orderBy, sortBy } from "lodash";
import get from "lodash/get";
import { duration } from "moment";

import { ListViewEvent } from "./atoms/timeline";
import { DayTotalsSettings } from "components/map/atoms/timeline/daytotals";

import { LatLng, SensorWithInterpretation, Vehicle, VehicleState } from "types";

import { DEFAULT_DATETIME_FORMAT, DEFAULT_TIME_FORMAT, durationFromNow, formatTime } from "utils/datetime-utils";
import { getCoordinate } from "utils/location-utils";

export type VehicleStatus = "driving" | "idling" | "parking" | "offline" | "towing" | "error";
export type BBox = [number, number, number, number];

export const VEHICLE_STATUS: { [key: string]: VehicleStatus } = {
  DRIVING: "driving",
  IDLING: "idling",
  PARKING: "parking",
  OFFLINE: "offline",
  TOWING: "towing",
  ERROR: "error",
};

const getTripMessage = (activity: string, start = true) => {
  if (start) {
    switch (activity) {
      case VEHICLE_STATUS.DRIVING:
        return t`Trip started`;
      case VEHICLE_STATUS.PARKING:
        return t`Parking started`;
      case VEHICLE_STATUS.IDLING:
        return t`Idling started`;
      case VEHICLE_STATUS.TOWING:
        return t`Towing started`;
    }
  }

  switch (activity) {
    case VEHICLE_STATUS.DRIVING:
      return t`Trip ended`;
    case VEHICLE_STATUS.PARKING:
      return t`Parking ended`;
    case VEHICLE_STATUS.IDLING:
      return t`Idling ended`;
    case VEHICLE_STATUS.TOWING:
      return t`Towing ended`;
  }
};

const DEFAULT_DURATION = 15 * 60; // 15min default

/* Determine vehicle status based on vehicles last state */
const getVehicleStatus = (vehicleState?: VehicleState, vehicle?: Vehicle) => {
  if (vehicle) {
    const activity = vehicleState?.activity;
    // fallback driving condition is defined by ignition status or speed >= 5 as fallback
    const drivingCondition = isUndefinedOrNull(vehicleState?.ignition)
      ? (vehicleState?.speed as any) >= 5
      : vehicleState?.ignition;

    // state timeout is defined by error_state_threshold with a 15-minute default
    const timeout = vehicle?.error_state_threshold
      ? duration(vehicle.error_state_threshold).asSeconds()
      : DEFAULT_DURATION;

    // error condition is defined by lack of state time or a time that exceeds the timeout
    const errorCondition = vehicleState?.time
      ? dayjs(vehicleState.time).isBefore(dayjs().subtract(timeout, "second"))
      : true;

    switch (true) {
      case errorCondition:
        // in case state time exceeds timeout, then status is
        // - parking for vehicles with GPS device that is switched on/off by ignition
        // - offline for vehicles with GPS device that is always connected to power
        if (vehicle?.switched_by_ignition) {
          return VEHICLE_STATUS.PARKING;
        } else {
          return VEHICLE_STATUS.OFFLINE;
        }
      case !isEmpty(activity):
        // the primary method to define the current status is the activity field value
        return activity;
      case drivingCondition === true:
        // fall back to driving status when drivingCondition is true
        return VEHICLE_STATUS.DRIVING;
      default:
        return VEHICLE_STATUS.PARKING;
    }
  }

  return null;
};

const getVehicleLastActivity = (started: string) => {
  const now = dayjs();
  const startDate = dayjs(started);
  const isToday = startDate.isSame(dayjs(), "day");
  const lastActivityStartTime = started
    ? startDate.format(isToday ? DEFAULT_TIME_FORMAT : DEFAULT_DATETIME_FORMAT)
    : null;

  const activityDuration = lastActivityStartTime ? durationFromNow(started, now.toISOString()) : null;

  return { lastActivityStartTime, activityDuration };
};

const sortVehicleSensors = (
  sensors: { [key: string]: SensorWithInterpretation[] },
  vehicleId: string,
  filterBy: string
) => {
  return sortBy(
    sensors[vehicleId]?.filter((sensor) => get(sensor, filterBy)),
    ["sort_order", "name_display"]
  );
};

const getVehicleSummary = (vehicles: { vehicleStatus: string }[]) => {
  const summary = { all: vehicles?.length || 0, driving: 0, towing: 0, idling: 0, parking: 0, offline: 0 };

  vehicles?.map((vehicle) => {
    switch (vehicle.vehicleStatus) {
      case VEHICLE_STATUS.DRIVING:
        summary["driving"] = summary["driving"] + 1;
        break;
      case VEHICLE_STATUS.TOWING:
        summary["towing"] = summary["towing"] + 1;
        break;
      case VEHICLE_STATUS.IDLING:
        summary["idling"] = summary["idling"] + 1;
        break;
      case VEHICLE_STATUS.PARKING:
        summary["parking"] = summary["parking"] + 1;
        break;
      case VEHICLE_STATUS.OFFLINE:
        summary["offline"] = summary["offline"] + 1;
        break;
    }
  });

  return summary;
};

const statusSortWeights: { [key: string]: number } = {
  driving: 1,
  towing: 2,
  idling: 3,
  parking: 4,
  offline: 5,
  error: 6,
};

const isUndefinedOrNull = (value: any) => {
  return value === undefined || value === null;
};

const isUndefinedOrNullOrNaN = (value: any) => {
  return value === undefined || value === null || isNaN(value);
};

const mergeDayTotalsSettingsWithValues = (
  dayTotals: { [key: string]: any },
  dayTotalsDataTypeAndUnit: { [key: string]: DayTotalsSettings }
) => {
  const result: { [key: string]: any } = {};

  if (dayTotals) {
    Object.keys(dayTotals).map((key: string) => {
      if (dayTotals[key] !== null && key !== "day") {
        result[key] = {
          key: key,
          icon: dayTotalsDataTypeAndUnit?.[key]?.icon,
          value: dayTotals?.[key],
          position: dayTotalsDataTypeAndUnit?.[key]?.position,
          label: dayTotalsDataTypeAndUnit?.[key]?.label,
          type: dayTotalsDataTypeAndUnit?.[key]?.type,
          unit: dayTotalsDataTypeAndUnit?.[key]?.unit,
          visible: dayTotalsDataTypeAndUnit?.[key]?.visible,
        };
      }
    });
  }

  return orderBy(Object.values(result), ["position"], ["asc"]);
};

interface DrivingAndParkingEvents {
  tripId?: string;
  address: string;
  location: LatLng | undefined;
  tripEvent: string;
  tripEventTime: number;
  icon: string;
  text: string;
  coordinates: [number, number];
  offset?: [number, number];
  textOffset?: [number, number];
  textColor?: [number, number, number];
  time?: number;
}

const getDrivingAndParkingEvents = (events?: ListViewEvent[]) => {
  const parkingAndDrivingEvents: DrivingAndParkingEvents[] = [];

  events?.forEach((listViewEvent: ListViewEvent, index: number) => {
    const latLng = getCoordinate(listViewEvent.location);

    if (listViewEvent?.vehicleStatus === VEHICLE_STATUS.DRIVING && listViewEvent.event?.trip?.start_time) {
      parkingAndDrivingEvents.push({
        tripId: listViewEvent.event.trip?.id,
        tripEvent: "trip_start",
        tripEventTime: dayjs(listViewEvent?.event?.time).unix(),
        address: listViewEvent?.event?.address,
        location: getCoordinate(listViewEvent.location),
        icon: `${window.location.origin}/icons/driving/start-${dayjs(listViewEvent?.event?.time).format("HHmm")}.png`,
        text: formatTime(listViewEvent?.event?.time),
        coordinates: [latLng?.lng as number, latLng?.lat as number],
        offset: [15, -18],
        time: dayjs(listViewEvent?.event?.time).unix(),
      });
    }

    const isFirstParkingEvent = index === events.length - 1;

    if (
      listViewEvent?.vehicleStatus === VEHICLE_STATUS.PARKING &&
      listViewEvent?.event?.trip?.start_time &&
      !isFirstParkingEvent
    ) {
      parkingAndDrivingEvents.push({
        tripId: listViewEvent.event.trip?.id,
        tripEvent: "trip_end",
        tripEventTime: dayjs(listViewEvent?.event?.time).unix(),
        address: listViewEvent?.event?.address,
        location: getCoordinate(listViewEvent.location),
        icon: `${window.location.origin}/icons/driving/stop-${dayjs(listViewEvent?.event?.time).format("HHmm")}.png`,
        text: formatTime(listViewEvent?.event?.time),
        coordinates: [latLng?.lng as number, latLng?.lat as number],
        offset: [-15, -18],
        time: dayjs(listViewEvent?.event?.time).unix(),
      });
    }
  });

  return parkingAndDrivingEvents;
};

const timelineURLQueryParams = (
  date: Dayjs | [Dayjs, Dayjs],
  url?: string,
  eventId?: string,
  removeTimeParam?: boolean
) => {
  const currentQueryParams = new URLSearchParams(window.location.search);

  if (isArray(date)) {
    currentQueryParams.set("start", date[0]?.format());
    currentQueryParams.set("end", date[1]?.format());
  } else {
    currentQueryParams.set("start", dayjs.tz(date)?.startOf("day").format());
    currentQueryParams.set("end", dayjs.tz(date)?.endOf("day").format());
  }

  if (eventId) {
    currentQueryParams.set("event", eventId);
  }

  if (removeTimeParam) {
    currentQueryParams.delete("time");
    currentQueryParams.delete("event");
  }

  return url + "?" + currentQueryParams.toString();
};

const setTimeInURLParams = (date: Dayjs, url?: string) => {
  const currentQueryParams = new URLSearchParams(window.location.search);

  if (date) {
    currentQueryParams.set("time", date?.format());
  }

  return url + "?" + currentQueryParams.toString();
};

const setTimelineEventIdInURLParams = (url?: string, eventId?: string) => {
  const currentQueryParams = new URLSearchParams(window.location.search);

  if (eventId) {
    currentQueryParams.set("event", eventId);
  } else {
    currentQueryParams.delete("event");
  }

  return url + "?" + currentQueryParams.toString();
};

const getLastUpdated = (time: string | number | Dayjs | Date | null | undefined) => {
  if (isEmpty(time)) {
    return null;
  }

  const updatedTime = dayjs(time);

  return dayjs.duration(dayjs().diff(updatedTime)).humanize();
};

const isPointInBox = (center: [number, number], bbox: any) => {
  const polygon = bboxPolygon(bbox as BBox);

  if (center?.length < 2) {
    return false;
  }

  const pt = point(center);

  return booleanPointInPolygon(pt, polygon, { ignoreBoundary: true });
};

const isChildElementFullyInParentElement = (container: HTMLDivElement, child: HTMLSpanElement | HTMLDivElement) => {
  if (container && child) {
    const containerRect = container.getBoundingClientRect();
    const childRect = child.getBoundingClientRect();

    return (
      childRect.top >= containerRect.top &&
      childRect.bottom <= containerRect.bottom &&
      childRect.left >= containerRect.left &&
      childRect.right <= containerRect.right
    );
  }

  return false;
};

export {
  getVehicleStatus,
  getVehicleLastActivity,
  getVehicleSummary,
  statusSortWeights,
  isUndefinedOrNull,
  sortVehicleSensors,
  mergeDayTotalsSettingsWithValues,
  getTripMessage,
  isUndefinedOrNullOrNaN,
  timelineURLQueryParams,
  getLastUpdated,
  getDrivingAndParkingEvents,
  setTimelineEventIdInURLParams,
  setTimeInURLParams,
  isPointInBox,
  isChildElementFullyInParentElement,
};
